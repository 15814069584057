module.exports = {
    BIDNBARGAIN_SS: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Projects%2FBidnBargain.png?alt=media&token=716b2788-fa04-4e1b-abc5-3e2908374726",
    BIDNBARGAIN_URL: "https://bnb-fcg1.onrender.com",
    PROFILE_PIC: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/About%2FTemp.jpg?alt=media&token=f628ad54-c805-4467-b3cd-606cebf7d7ad",
    JAVA_LOGO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Logo%2Fjava-logo.svg?alt=media&token=6cab8d59-b0c4-476a-a5eb-cbeb5ee2bb8c",
    JAVASCRIPT_LOGO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Logo%2Fjavascript-logo.svg?alt=media&token=0cc38979-130f-4871-8ebf-b81d9c8e9350",
    HTML_LOGO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Logo%2Fw3_html5-logo.svg?alt=media&token=ed925950-7771-49d1-beb9-39dcc25b5b82",
    CSS_LOGO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Logo%2Fw3_css-logo.svg?alt=media&token=cd02d0ad-b65b-4d7c-a73d-920961bbe7f3",
    REACT_LOGO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Logo%2Freactjs-logo.svg?alt=media&token=1143f24a-b914-4cc0-aa70-ff0faea16329",
    REDUX_LOGO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Logo%2Fredux-logo.svg?alt=media&token=d17dee10-35dc-4ee4-ae93-43642c4b03be",
    JSON_LOGO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Logo%2Fjson-logo.svg?alt=media&token=41052645-0fe9-4806-92fb-b4ca521e28ce",
    SPRING_LOGO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Logo%2Fspringio-logo.svg?alt=media&token=4d2744ba-a761-4b22-b166-01ca7170f659",
    NODE_LOGO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Logo%2Fnodejs-logo.svg?alt=media&token=25efb9c1-e4ea-49ed-9570-5a5956f0d441",
    EXPRESS_LOGO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Logo%2Fexpressjs-logo.svg?alt=media&token=55b184af-1292-4085-ba86-1d59fbeea622",
    JENKINS_LOGO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Logo%2Fjenkins-logo.svg?alt=media&token=028d9321-be60-4f63-b615-fccc95544dd0",
    MAVEN_LOGO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Logo%2Fmaven-logo.svg?alt=media&token=9a7c30a1-50a3-4881-a3b8-9a2132a0d181",
    KAFKA_LOGO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Logo%2Fapache_kafka-logo.svg?alt=media&token=d04c495c-7bae-49d8-a254-7879918c4ea9",
    DOCKER_LOGO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Logo%2Fdocker-logo.svg?alt=media&token=b6fdf320-2d0d-4dbf-b971-40567aa8d7a8",
    JUNIT_LOGO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Logo%2Fjunit5-logo.png?alt=media&token=1012fdba-b4bd-4de0-a57a-f0f292b15c7f",
    MYSQL_LOGO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Logo%2Fmysql-logo.svg?alt=media&token=2bc9cd52-3b9e-4fe9-9395-beb4e348cbe5",
    MONGODB_LOGO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Logo%2Fmongodb-logo.svg?alt=media&token=f9bb4e27-c5b8-4a0d-b136-789c4546a5ef",
    AWS_LOGO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Logo%2Famazon_aws-icon.svg?alt=media&token=2dd1a0b0-264b-4b43-a3bf-b3291f379427",
    GCP_LOGO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Logo%2Fgoogle_cloud-icon.svg?alt=media&token=cd8588dd-5c04-4d22-9f33-1615eab61ed0",
    GIT_LOGO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Logo%2Fgit-scm-logo.svg?alt=media&token=884718ce-f3b9-4225-9d31-c2026b1a544c",
    ECLIPSE_LOGO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Logo%2Feclipse-logo.svg?alt=media&token=5c90bf28-ac6f-4cff-8430-09a00af81bcb",
    INTELLIJ_LOGO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Logo%2FIntelliJ_IDEA_logo.svg?alt=media&token=2f0a5adb-246f-4a7d-a8b4-adf5dbad971b",
    VSCODE_LOGO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Logo%2Fvisualstudio_code-icon.svg?alt=media&token=584c141e-75f8-4ff1-9996-4fb22bfa5761",
    AZURE_CERTIFICATE_PHOTO: "https://firebasestorage.googleapis.com/v0/b/sahil-portfolio-7727b.appspot.com/o/Certification%2FAzure_fundamentals.png?alt=media&token=ccbb589f-6be3-4eb0-bcf3-7505a794b2c7",
    AZURE_CERTIFICATE_URL: "https://www.credly.com/badges/982c0c41-14c4-4799-aecf-4610dd6e06c0",
}